import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Employee } from '../../../modules/cs/common/components/employee';

import '../../generic-page.scss';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const CSShadowPalaceF3Page: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Shadow Palace - Floor 3</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/shadow_floor_3.png"
            alt="Shadow Palace"
          />
        </div>
        <div className="page-details">
          <h1>Shadow Palace - Floor 3</h1>
          <h2>Guide for the third floor of Shadow Palace.</h2>
        </div>
      </div>
      <div className="page-content">
        <SectionHeader title="MVP employees" />
        <div className="employee-container">
          <Employee slug="awakened-lee-sooyeon" mode="icon" enablePopover />
          <Employee slug="lyudmila" mode="icon" enablePopover />
          <Employee slug="rosaria-le-friede" mode="icon" enablePopover />
          <Employee slug="janus" mode="icon" enablePopover />
          <Employee slug="evelyn-keller" mode="icon" enablePopover />
          <Employee slug="sparrow" mode="icon" enablePopover />
          <Employee slug="woodpecker" mode="icon" enablePopover />
          <Employee slug="atf-35-thunderbolt" mode="icon" enablePopover />
        </div>
        <p>
          I think you already noticed the theme. You will need Air units here or
          you’ll need to actually think.
        </p>
        <div className="employee-container">
          <Employee slug="awakened-hilde" mode="icon" enablePopover />
          <Employee slug="lee-yumi" mode="icon" enablePopover />
          <Employee slug="serapel" mode="icon" enablePopover />
          <Employee slug="stronghold" mode="icon" enablePopover />
        </div>
        <p>
          If you don’t want to cheese the floor with air units, you’ll need a
          tank with MDL to tank Sha(d)o(w) Lin’s.
        </p>
        <div className="employee-container">
          <Employee slug="kyle-wong" mode="icon" enablePopover />
          <Employee slug="regina-maccready" mode="icon" enablePopover />
          <Employee slug="edel-meitner" mode="icon" enablePopover />
        </div>
        <p>
          Kyle, Regina, and Edel can produce disposable units to tank Shadow
          Lin’s shots.
        </p>
        <div className="employee-container">
          <Employee slug="administration-shieldman" mode="icon" enablePopover />
          <Employee slug="shieldman" mode="icon" enablePopover />
        </div>
        <p>
          Well... there are two of them, so they get two-shot instead of
          one-shot.
        </p>
        <SectionHeader title="Enemies" />
        <StaticImage
          src="../../../images/counterside/shadow/AB_INVEN_ICON_NKM_SHADOW_C_ALT_XIAOLIN.png"
          alt="Shadow Enemy"
        />
        <h4>Shadow Xiao</h4>
        <p>She one-shots any ground unit that isn’t a tank with MDL.</p>
        <StaticImage
          src="../../../images/counterside/shadow/AB_INVEN_ICON_NKM_SHADOW_S_ESPR_PILE.png"
          alt="Shadow Enemy"
        />
        <h4>Shadow Rivet</h4>
        <p>
          Scary wabbit, but thankfully she -similar to Xiao-only targets ground
          units. She deals increased damage to Counters too which proves deadly.
          She’s the reason why this floor is considered annoying because
          sometimes her special can wipe your whole team and it’s really RNG
          based because of the massive AOE that can randomly hit your backline.
        </p>
        <StaticImage
          src="../../../images/counterside/shadow/AB_INVEN_ICON_NKM_SHADOW_C_ZODIAC_LIV.png"
          alt="Shadow Enemy"
        />
        <h4>Shadow Liv</h4>
        <p>
          Has tons of evasion and is super tanky, but doesn’t do anything
          besides that (unless you let her get to your ship, then she will
          pummel it to death)
        </p>
        <h4>Ship</h4>
        <p>
          The New Detroit acts differently in Shadow Palace. Basically, each
          time a ground unit crosses half of the map it will launch a skill that
          does quite a lot of damage and pushes everyone back. That’s why pure
          Air teams are so popular here because not only Xiao/Rivet can’t hit
          them, but also the ship is neutralized.{' '}
        </p>
        <SectionHeader title="Restrictions" />
        <ul>
          <li>Room 1 - Snipers are banned</li>
          <li>Room 2 - Strikers are banned</li>
          <li>Room 3 - Defenders are banned</li>
          <li>Room 4 - Max 25 deployment cost</li>
          <li>Room 5 - Max 25 deployment cost and Awakened units are banned</li>
        </ul>
        <SectionHeader title="Important" />
        <p>
          While floors 1 and 2 allowed you to slap random units together or use
          Awakened units to carry you, floor 3 isn’t that nice. Basically,
          you’re forced to use Air teams or you will get punished by the
          mechanics on the floor. Still, you can pass the stages with Ground
          units, but it requires manual gameplay/good gear/A.Hilde.
        </p>
        <p>
          If you don’t have enough Air units, use Towers like Choi Ina/Gabriel
          and drop them on your side of the field. A lot of teams will use ALSY
          here and it’s really hard to replace her.
        </p>
        <SectionHeader title="Room 1" />
        <p>
          No snipers on this floor mean no Lyudmila and you have to use other
          niche Air units as fodder (or towers). So get your Black
          Tails/Reapers/Sparrows/etc out of the garage!
        </p>
        <h4>Example team - air (order matters):</h4>
        <div className="employee-container">
          <Employee slug="janus" mode="icon" enablePopover />
          <Employee slug="awakened-lee-sooyeon" mode="icon" enablePopover />
          <Employee slug="sparrow" mode="icon" enablePopover />
          <Employee slug="woodpecker" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="atf-35-thunderbolt" mode="icon" enablePopover />
          <Employee slug="black-tail" mode="icon" enablePopover />
          <Employee slug="rosaria-le-friede" mode="icon" enablePopover />
          <Employee slug="evelyn-keller" mode="icon" enablePopover />
        </div>
        <h4 className="bigger-margin">
          Example team - ground (order matters):
        </h4>
        <div className="employee-container">
          <Employee slug="administration-shieldman" mode="icon" enablePopover />
          <Employee slug="serapel" mode="icon" enablePopover />
          <Employee slug="kyle-wong" mode="icon" enablePopover />
          <Employee slug="rosaria-le-friede" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="eins-and-zwei" mode="icon" enablePopover />
          <Employee slug="evelyn-keller" mode="icon" enablePopover />
          <Employee slug="administration-rifleman" mode="icon" enablePopover />
          <Employee slug="yang-harim" mode="icon" enablePopover />
        </div>
        <SectionHeader title="Room 2" />
        <p>Strikers are banned this time here.</p>
        <h4>Example team - air (order matters):</h4>
        <div className="employee-container">
          <Employee slug="awakened-lee-sooyeon" mode="icon" enablePopover />
          <Employee slug="janus" mode="icon" enablePopover />
          <Employee slug="lyudmila" mode="icon" enablePopover />
          <Employee slug="atf-35-thunderbolt" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="sparrow" mode="icon" enablePopover />
          <Employee slug="buzzard" mode="icon" enablePopover />
          <Employee slug="rosaria-le-friede" mode="icon" enablePopover />
          <Employee slug="black-tail" mode="icon" enablePopover />
        </div>
        <h4 className="bigger-margin">
          Example team - ground (order matters):
        </h4>
        <div className="employee-container">
          <Employee slug="administration-shieldman" mode="icon" enablePopover />
          <Employee slug="serapel" mode="icon" enablePopover />
          <Employee slug="administration-rifleman" mode="icon" enablePopover />
          <Employee slug="kyle-wong" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="evelyn-keller" mode="icon" enablePopover />
          <Employee slug="yang-harim" mode="icon" enablePopover />
          <Employee slug="rosaria-le-friede" mode="icon" enablePopover />
          <Employee slug="seo-yoon" mode="icon" enablePopover />
        </div>
        <SectionHeader title="Room 3" />
        <p>
          Defenders are banned this time, but you don’t really use them on this
          floor so it’s not a big loss (at least for the air teams). River is
          really RNG character and if she uses her special at a wrong moment it
          can wipe your team.
        </p>
        <h4>Example team - air (order matters):</h4>
        <div className="employee-container">
          <Employee slug="lyudmila" mode="icon" enablePopover />
          <Employee slug="buzzard" mode="icon" enablePopover />
          <Employee slug="awakened-lee-sooyeon" mode="icon" enablePopover />
          <Employee slug="sparrow" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="woodpecker" mode="icon" enablePopover />
          <Employee slug="janus" mode="icon" enablePopover />
          <Employee slug="rosaria-le-friede" mode="icon" enablePopover />
          <Employee slug="choi-ina" mode="icon" enablePopover />
        </div>
        <h4 className="bigger-margin">
          Example team - ground (order matters):
        </h4>
        <div className="employee-container">
          <Employee slug="eins-and-zwei" mode="icon" enablePopover />
          <Employee slug="cindy-looper" mode="icon" enablePopover />
          <Employee slug="kyle-wong" mode="icon" enablePopover />
          <Employee slug="yang-harim" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="administration-rifleman" mode="icon" enablePopover />
          <Employee slug="rosaria-le-friede" mode="icon" enablePopover />
          <Employee slug="evelyn-keller" mode="icon" enablePopover />
          <Employee
            slug="administration-sword-fighter"
            mode="icon"
            enablePopover
          />
        </div>
        <SectionHeader title="Room 4" />
        <p>
          This is where the issues start because the deployment cost is quite
          punishing and even if you have ALSY it will be hard for you to fit
          here in your team. A lot of people get stuck on this and the last room
          especially.
        </p>
        <h4>Example team - air (order matters):</h4>
        <div className="employee-container">
          <Employee slug="woodpecker" mode="icon" enablePopover />
          <Employee slug="lyudmila" mode="icon" enablePopover />
          <Employee slug="rosaria-le-friede" mode="icon" enablePopover />
          <Employee slug="sparrow" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="atf-35-thunderbolt" mode="icon" enablePopover />
          <Employee slug="buzzard" mode="icon" enablePopover />
          <Employee slug="black-tail" mode="icon" enablePopover />
          <Employee
            slug="gabriel-jun-the-vicious-breaker"
            mode="icon"
            enablePopover
          />
        </div>
        <h4 className="bigger-margin">
          Example team - ground (order matters):
        </h4>
        <div className="employee-container">
          <Employee slug="administration-shieldman" mode="icon" enablePopover />
          <Employee slug="serapel" mode="icon" enablePopover />
          <Employee slug="edel-meitner" mode="icon" enablePopover />
          <Employee slug="kyle-wong" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="evelyn-keller" mode="icon" enablePopover />
          <Employee slug="eins-and-zwei" mode="icon" enablePopover />
          <Employee slug="yang-harim" mode="icon" enablePopover />
          <Employee slug="administration-rifleman" mode="icon" enablePopover />
        </div>
        <SectionHeader title="Room 5" />
        <p>
          Cost restriction + awakened unit banned. Not that it changes anything,
          because it’s hard to use ALSY with the cost restriction here anyway.
          Prepare yourself for the possibility of being stuck here for some
          time.
        </p>
        <h4>Example team - air (order matters):</h4>
        <div className="employee-container">
          <Employee slug="lyudmila" mode="icon" enablePopover />
          <Employee slug="janus" mode="icon" enablePopover />
          <Employee slug="woodpecker" mode="icon" enablePopover />
          <Employee slug="sparrow" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="atf-35-thunderbolt" mode="icon" enablePopover />
          <Employee slug="buzzard" mode="icon" enablePopover />
          <Employee slug="black-tail" mode="icon" enablePopover />
          <Employee
            slug="gabriel-jun-the-vicious-breaker"
            mode="icon"
            enablePopover
          />
        </div>
        <h4 className="bigger-margin">
          Example team - ground (order matters):
        </h4>
        <div className="employee-container">
          <Employee slug="edel-meitner" mode="icon" enablePopover />
          <Employee slug="serapel" mode="icon" enablePopover />
          <Employee slug="administration-shieldman" mode="icon" enablePopover />
          <Employee slug="kyle-wong" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="evelyn-keller" mode="icon" enablePopover />
          <Employee slug="rosaria-le-friede" mode="icon" enablePopover />
          <Employee slug="yang-harim" mode="icon" enablePopover />
          <Employee slug="eins-and-zwei" mode="icon" enablePopover />
        </div>
        <SectionHeader title="Video guide" subtitle="Credits to CymenSniped" />
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="DVzZp2k85oU" />
          </Col>
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default CSShadowPalaceF3Page;

export const Head: React.FC = () => (
  <Seo
    title="Shadow Palace 3 guide | Counter Side | Prydwen Institute"
    description="Guide for the third floor of Shadow Palace."
  />
);
